<template>
  <div>
    <van-cell-group>
      <van-cell title="社区"
        :value="hzlist.OrgName" />
      <van-cell title="小区"
        :value="hzlist.RName" />
      <van-cell title="姓名"
        :value="hzlist.Name" />
      <van-cell title="身份证号"
        :value="hzlist.IDCard" />
      <van-cell title="联系方式"
        :value="hzlist.Mobile" />
      <van-cell title="家庭住址"
        :value="hzlist.Address" />
      <van-cell title="接种状态"
        :value="hzlist.StatusDesc" />
      <van-cell title="接种时间"
        :value="hzlist.VaccinateTime" />
      <van-cell title="接种提醒"
        :value="hzlist.RemindDesc" />
    </van-cell-group>
  </div>
</template>
<script>
import { GetResident } from "@/api/wangge";
import { getwgToken } from "@/utils/auth";
export default {
  data () {
    return {
      listfrom: {},
      hzlist: []
    }
  },
  created () {
    this.cyXq();
  },
  methods: {
    // 获取住户列表
    cyXq () {
      console.log(this.$route);
      console.log(JSON.parse(this.$route.params.itemmark));
      this.hzlist = JSON.parse(this.$route.params.itemmark)
      // this.listfrom.hId = this.$route.query.HId;
      // this.listfrom.accToken = getwgToken();
      // GetResident(this.listfrom)
      //   .then((res) => {
      //     if (res.data.code == 0) {
      //       if (res.data.count == 0) {
      //         this.hzlist = {};
      //       } else {
      //         this.hzlist = res.data.data;
      //       }
      //     }
      //   })
      //   .catch(() => { });
    },
  },
}
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
</style>